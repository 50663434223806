<template>
    <div class="price-negotiation">

        <div
            v-if="!pageLoad"
            class="page-loader">
            <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>

        <div class="page-title d-flex py-2 justify-content-between">

            <h1 class="h4 mb-0">Запрос на согласование цен № {{ negotiation.number }} от {{ negotiation.date | parseDate }}</h1>

            <div class="d-flex align-items-center">
                <BButton
                    class="ml-3"
                    variant="warning"
                    @click="onExport( 'price_negotiation', id )"
                >Экспорт
                </BButton>
                <BButton
                    v-if="negotiation.organization_status === 'Черновик'"
                    class="ml-3"
                    variant="outline-primary"
                    :to="`${$route.fullPath}/edit`">Редактировать
                </BButton>
            </div>

        </div>

        <div
            class="mb-4"
        >

            <div class="d-flex table-bordered mb-1">
                <div class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0" style="width: 250px;">Тип запроса</div>
                <div class="px-2 py-1 flex-grow-1">{{ getType }}</div>
            </div>

            <b-row>

                <b-col cols="6">
                    <div class="d-flex table-bordered mb-1">
                        <div
                            class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
                            style="width: 250px;"
                        >№ запроса
                        </div>
                        <div class="px-2 py-1 flex-grow-1">{{ negotiation.number }}</div>
                    </div>
                </b-col>

                <b-col cols="6">
                    <div class="d-flex table-bordered mb-1">
                        <div
                            class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
                            style="width: 250px;"
                        >Дата запроса
                        </div>
                        <div class="px-2 py-1 flex-grow-1">{{ negotiationDatetime }}</div>
                    </div>
                </b-col>

            </b-row>

            <div
                class="d-flex table-bordered mb-1"
            >
                <div class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0" style="width: 250px;">
                    <span>№ договора {{ ( ( { 'Договор по хоз. способу': 'поставки', 'Подрядные работы': 'подряда' } )[ getType ] ) }}</span>
                </div>
                <div class="px-2 py-1 flex-grow-1">{{ workAgreementName }}</div>
            </div>

            <div class="d-flex table-bordered mb-1">
                <div class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0" style="width: 250px;">Статус</div>
                <div class="px-2 py-1 flex-grow-1">{{ negotiation.organization_status }}</div>
            </div>

            <div
                v-if="isContractor || ( isProvider && negotiation.type === 'contract_work' )"
                class="d-flex table-bordered mb-1"
            >
                <div class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0" style="width: 250px;">Объект</div>
                <div class="px-2 py-1 flex-grow-1">{{ negotiation.order ? getObject : '' }}</div>
            </div>

            <div
                v-if="isContractor"
                class="d-flex table-bordered mb-1"
            >
                <div class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0" style="width: 250px;">Подобъект</div>
                <div class="px-2 py-1 flex-grow-1">{{ negotiation.order ? getSubObject : '' }}</div>
            </div>

            <div
                class="d-flex table-bordered mb-1"
            >
                <div class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0" style="width: 250px;">Поставщик</div>
                <div class="px-2 py-1 flex-grow-1">{{ negotiation.order && negotiation.order.provider && negotiation.order.provider.contr_agent && negotiation.order.provider.contr_agent.name }}</div>
            </div>

            <div
                v-if="isContractor || ( isProvider && getType === 'Подрядные работы' )"
                class="d-flex table-bordered mb-1"
            >
                <div class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0" style="width: 250px;">Подрядчик</div>
                <div class="px-2 py-1 flex-grow-1">{{ negotiation.order && negotiation.order.contractor && negotiation.order.contractor.contr_agent && negotiation.order.contractor.contr_agent.name }}</div>
            </div>

            <div
                class="d-flex table-bordered mb-1"
            >
                <div class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0" style="width: 250px;">Филиал</div>
                <div class="px-2 py-1 flex-grow-1">{{ getOrganization.name }}</div>
            </div>

            <b-row>

                <b-col cols="12">
                    <div class="d-flex table-bordered mb-1">
                        <div
                            class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
                            style="width: 250px;"
                        >Заказ на поставку
                        </div>
                        <div class="px-2 py-1 flex-grow-1">{{ negotiation.order && ( negotiation.order.is_generated ? null : negotiation.order.number ) }}</div>
                    </div>
                </b-col>

            </b-row>

            <!-- <b-row
                v-if="isContractor"
            >

                <b-col cols="12">
                    <div class="d-flex table-bordered mb-1">
                        <div
                            class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
                            style="width: 250px;"
                        >№ договора поставки
                        </div>
                        <div class="px-2 py-1 flex-grow-1">{{ negotiation.order && negotiation.order.provider && negotiation.order.provider.contract && negotiation.order.provider.contract.number }}</div>
                    </div>
                </b-col>

            </b-row> -->

            <div class="d-flex table-bordered mb-1">
                <div
                    class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
                    style="width: 250px;"
                >Страна
                </div>
                <div class="px-2 py-1 flex-grow-1">{{ getCountriesOptions && getCountriesOptions.length && ( getCountriesOptions.find( el => el.value === negotiation.country ) || {} ).text }}</div>
            </div>

            <b-row>

                <b-col cols="6">
                    <div class="d-flex table-bordered mb-1">
                        <div
                            class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
                            style="width: 250px;"
                        >Ответственный
                        </div>
                        <div class="px-2 py-1 flex-grow-1">{{ negotiation.responsible_full_name }}</div>
                    </div>
                </b-col>

                <b-col cols="6">
                    <div class="d-flex table-bordered mb-1">
                        <div
                            class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
                            style="width: 250px;"
                        >Телефон
                        </div>
                        <div class="px-2 py-1 flex-grow-1">{{ negotiation.responsible_phone | VMask('# (###) ###-##-##') }}</div>
                    </div>
                </b-col>

            </b-row>

            <div class="d-flex table-bordered mb-1">
                <div class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0" style="width: 250px;">Комментарий</div>
                <div class="px-2 py-1 flex-grow-1">{{ negotiation.comment }}</div>
            </div>

            <div
                v-if="negotiation.files"
                class="d-flex table-bordered mb-1"
            >
                <div class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0" style="width: 250px;">Приложение</div>
                <div class="px-2 py-1 flex-grow-1">
                    <DownloadLink style="padding-right: 5px;"
                        v-for="(file, idx) of negotiation.files"
                        :key="idx"
                        :id="file.id"
                        :from="`price-negotiations/${ negotiation.id }/files`"
                        :url="file.url"
                    />
                </div>
            </div>

        </div>

        <div class="mb-4">
            <div class="h4 mb-3">Позиция</div>

            <DefaultTable
                v-if="negotiation.positions"
                :data="negotiation.positions"
                :fields="positionsFields"
                emptyText="Нет позиций"
                :pagination="false"
            />
        </div>

        <div>
            <div class="h4 mb-3">Комментарии</div>

            <DefaultTable
                v-if="comments"
                :data="comments"
                :fields="commentsFields"
                emptyText="Нет комментариев"
                :pagination="false"
            />
        </div>
    </div>
</template>

<script>
import Axios from 'axios'
import moment from 'moment'
import { mapState, mapGetters } from 'vuex'
import DefaultTable from "@/components/Tables/Default"
import DownloadLink from "@/components/UI/DownloadLink"

export default {
    name: "PriceNegotiation",
    components: {
        DownloadLink,
        DefaultTable
    },
    computed: {
        id () {
            return this.$route.params.id
        },
        ...mapState({
            negotiation: state => state.priceNegotiations.currentNegotiation
        }),
        ...mapGetters({
            isContractor: 'isContractor',
            isProvider: 'isProvider',
            getOrganizationById: 'getOrganization',
            workAgreementsOptions: 'getWorkAgreementsOptions',
            getCountriesOptions: 'getCountriesOptions'
        }),
        getType() {
            switch (this.negotiation.type) {
                case 'contract_home_method':
                    return 'Договор по хоз. способу'
                case 'contract_work':
                    return 'Подрядные работы'
                default:
                    return 'Не указано'
            }
        },
        getOrganization() {
            let res = this.getOrganizationById(this.negotiation.order?.customer?.organization_id)
            return res ? res : {}
        },
        getObject() {
            if (this.negotiation.order.customer?.object) {
                return this.negotiation.order.customer.object.name
            } else {
                return ''
            }
        },
        getSubObject() {
            if (this.negotiation.order.customer?.sub_object) {
                return this.negotiation.order.customer.sub_object.name
            } else {
                return ''
            }
        },
        breadcrumbs () {
            return [
                { title: 'Главная', link: '/' },
                { title: 'Перечень согласования цен', link: '/price-negotiations' },
                { title: `Запрос на согласование цен № ${this.negotiation.number} от ${this.$options.filters.parseDate(this.negotiation.date)}` }
            ]
        },
        workAgreementName() {
            return this.negotiation?.work_agreement?.number || '';
        },
        negotiationDatetime() {
            if ( this.negotiation && this.negotiation.created_at )
                return moment( this.negotiation.created_at ).format( 'DD.MM.YYYY в HH:mm' )
            return null
        }
    },
    data: () => ({
        pageLoad: false,
        positionsFields: [
            {
                key: 'mnemocode',
                sortable: true,
                label: 'Мнемокод',
                full: 'Мнемокод',
                openDefault: true
            },
            {
                key: 'nomenclature',
                sortable: true,
                label: 'Номенклатура',
                full: 'Номенклатура',
                openDefault: true
            },
            {
                key: 'unit',
                sortable: true,
                label: 'Ед. изм.',
                full: 'Единица измерения',
                openDefault: true
            },
            {
                key: 'current_price_without_vat',
                sortable: true,
                label: 'Текущая цена без НДС',
                full: 'Текущая цена без НДС',
                openDefault: true
            },
            {
                key: 'new_price_without_vat',
                sortable: true,
                label: 'Новая цена без НДС',
                full: 'Новая цена без НДС',
                openDefault: true
            }
        ],
        commentsFields: [
            {
                key: 'id',
                label: '№ комментария',
                full: '№ комментария',
                openDefault: true
            },
            {
                key: 'question_text',
                label: 'Текст комментария',
                full: 'Текст комментария',
                openDefault: true
            },
            {
                key: 'question_date',
                label: 'Дата комментария',
                full: 'Дата комментария',
                openDefault: true
            },
            {
                key: 'ask_text',
                label: 'Текст ответа',
                full: 'Текст ответа',
                openDefault: true
            },
            {
                key: 'ask_date',
                label: 'Дата ответа',
                full: 'Дата ответа',
                openDefault: true
            }
        ],
        comments: null
    }),
    methods: {
        sendApprove () {
            this.$router.push('/price-negotiations')
        }
    },
    async mounted () {
        await this.$store.dispatch('getCurrentNegotiation', this.id)
        this.pageLoad = true

        const response = await Axios.get( `/price-negotiations/${ this.id }/comments` )
        this.comments = response.data.data
    }
}
</script>

<style
    lang="sass"
    scoped
>

</style>
